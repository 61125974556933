.pagination {
  text-align: center;
  padding: 0;
}

.pagination li {
  display: inline-block;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 0 2px;
  cursor: pointer;
}

.pagination li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #2a184b;
}

.pagination .active {
  background-color: #6638b6;
}

.pagination .active a {
  color: #ffffff;
}
